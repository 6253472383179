import React from "react";
import ConfirmEmail from "../Components/ConfirmEmail";

function ConfirmEmailPage() {
  return (
      <ConfirmEmail />
  );
}

export default ConfirmEmailPage;
