import React from "react";
import { Link } from "react-router-dom";
import { NavBtn } from "../Buttons/Buttons";
import logo from "../../assets/logo.webp";
import GetStepModal from "../GetStepModal";
import Modal from "react-modal";

import closebtn from "../../assets/Menu.png";

function MobileModal({
  handleToggle,
  setShowModal,
  modalIsOpen,
  openModal,
  closeModal,
  closeIcon,
}) {
  Modal.setAppElement("#root");
  Modal.defaultStyles.overlay.backgroundColor = "white";
  return (
    <div className="mobile-modal_container">
      <div className="container">
        <nav className="navbar-container">
          <div className="nav-items-container">
            <Link to="/" onClick={() => setShowModal(false)}>
              <div className="nav-logo">
                <img src={logo} alt="" />
              </div>
            </Link>
            <div className="hamburger-menu" onClick={handleToggle}>
              <img src={closebtn} alt="" />
            </div>
          </div>
        </nav>
        <ul className="mobile-nav_links">
          <li onClick={() => setShowModal(false)}>
            <Link to="/businesses">Business</Link>
          </li>
          <li onClick={() => setShowModal(false)}>
            <Link to="/creators">Creators</Link>
          </li>
          <li onClick={openModal}>
            <NavBtn />
          </li>
        </ul>
        <Modal
          isOpen={modalIsOpen}
          className="GetStepModal"
          overlayClassName="Overlay"
        >
          <div className="getStep modal-close-btn">
            <img src={closeIcon} alt="" onClick={closeModal} />
          </div>
          <div className="getStep-modal-content-container">
            <GetStepModal />
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default MobileModal;
