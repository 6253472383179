import React from "react";
import communityImage from "../../assets/missionImage.png";
import { PrimaryBtn } from "../Buttons/Buttons";
import "./style.css";

function Mission() {
  return (
    <section className="mission-wrapper">
      <div className="container">
        <div className="mission-content">
          <h3>ALL ARE WELCOME</h3>
          <h1>Mission driven technology for the global community</h1>
          <p>
            We’re committed to breaking down barriers that exists in all areas
            around the world
          </p>
          <div className="mission-content-image">
            <img src={communityImage} alt="" />
            <div className="overlay-btn">
              <PrimaryBtn />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Mission;
