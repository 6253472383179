import React from "react";
import "./style.css";

function index() {
  return (
    <>
      {/* <div className="app-img">
        <img src={appImg} alt="" />
      </div>
      <div className="getStep-content-container">
        <div className="topLeftCorner">
          <img src={c3} alt="" />
        </div>
        <div className="topRightCorner">
          <img src={c4} alt="" />
        </div>
        <h1>
          Get <span>STEP</span>
        </h1>
        <p>Scan QR code to download step</p>
        <div className="qr-code-container">
          <img src={qrCode} alt="" />
        </div>
        <h3>Download from app stores</h3>
        <div className="store-links">
          <button>
            <img src={playStore} alt="" />
          </button>
          <button>
            <img src={appStore} alt="" />
          </button>
        </div>
        <div className="bottomLeftCorner">
          <img src={c1} alt="" />
        </div>
        <div className="bottomRightCorner">
          <img src={c2} alt="" />
        </div>
      </div> */}
      <iframe
        id="waitlist"
        src="https://www.cognitoforms.com/f/rjtMA1PBXkWVxKl6xdjuIA/1"
        style={{
          border: 0,
          width: 90 + "%",
          height: 100 + "vh",
          overflow: "hidden",
        }}
        title="waitlist"
      ></iframe>
    </>
  );
}

export default index;
