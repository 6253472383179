import React, { useState } from "react";
import { PrimaryBtn } from "../Buttons/Buttons";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Modal from "react-modal";

import closeIcon from "../../assets/closeIcon.svg";
import GetStepModal from "../GetStepModal";
// import Tooltip from "react-tooltip-lite";

import image1 from "../../assets/creatorsImg.jpg";

import "./style.css";

function Creators() {
  Modal.setAppElement("#root");
  Modal.defaultStyles.overlay.backgroundColor = "white";
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <>
      <section className="component-wrapper">
        <div className="container">
          <div className="creators component-content">
            <div className="creators component-content-header">
              <div className="component-content-text-container">
                <h1 className="title">
                  Turn <span>Followers</span> into Paying Customers{" "}
                  <span>10x</span> Faster
                </h1>
                <p className="subtitle">
                  Engage, Excite and Monetize Your Communities
                </p>
                {/* <Tooltip
                  content="Coming soon"
                  direction="down"
                  background="black"
                  color="white"
                  padding="20px"
                  tipContentClassName="tooltip"
                > */}
                <div className="accessBtn" onClick={openModal}>
                  <PrimaryBtn />
                </div>
                {/* </Tooltip> */}
              </div>
              <div className="component-content-image-container">
                <div className="grid-image1">
                  <img src={image1} alt="" />
                </div>
              </div>
              <Modal
                isOpen={modalIsOpen}
                className="GetStepModal"
                overlayClassName="Overlay"
              >
                <div className="getStep modal-close-btn">
                  <img src={closeIcon} alt="" onClick={closeModal} />
                </div>
                <div className="getStep-modal-content-container">
                  <GetStepModal />
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Creators;
