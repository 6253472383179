import React from "react";

import "./style.css";
import arrow from "../../assets/arrow.svg";

function ContactUs() {
  return (
    <section className="contact-wrapper">
      <div className="container">
        <div className="contact-content">
          <div className="heading">
            <h1>Contact Us</h1>
          </div>
          <div className="grid-container">
            <div className="grid-box general">
              <div className="grid-img" />
              <h3>General Enquiry</h3>

              <a href="mailto:info@stepng.com" className="text">
                <p>info@stepng.com</p>
                <img src={arrow} alt={arrow} />
              </a>
            </div>
            <div className="grid-box media">
              <div className="grid-img" />
              <h3>Media Inquiries</h3>

              <a href="mailto:press@stepng.com" className="text">
                <p>press@stepng.com</p>
                <img src={arrow} alt={arrow} />
              </a>
            </div>
            <div className="grid-box team">
              <div className="grid-img" />
              <h3>Join The Team</h3>

              <a href="mailto:hiring@stepng.com" className="text">
                <p>hiring@stepng.com</p>
                <img src={arrow} alt={arrow} />
              </a>
            </div>
            <div className="grid-box support">
              <div className="grid-img" />
              <h3>Customer Support</h3>

              <a href="mailto:support@stepng.com" className="text">
                <p>support@stepng.com</p>
                <img src={arrow} alt={arrow} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
