const jobs = [
  {
    id: "83a13d75-b120-4724-bbf9-762d48098581",
    jobCategory: "Design",
    jobPosition: "Senior, UI/UX Designer",
    jobPeriod: "Full-time",
    jobLocation: "Lagos",
  },
  {
    id: "b13fe6f5-a889-415d-be0e-4530abb62b7a",
    jobCategory: "Engineering",
    jobPosition: "Junior, Frontend Developer",
    jobPeriod: "Full-time",
    jobLocation: "Lagos",
  },
  {
    id: "6788e74a-cbf1-426f-9574-224e3aeddd37",
    jobCategory: "Design",
    jobPosition: "Head, Product Design",
    jobPeriod: "Full-time",
    jobLocation: "Lagos",
  },
  {
    id: "beccddcc-7bd9-4dd8-b219-3725260b3287",
    jobCategory: "Engineering",
    jobPosition: "Senior, Software Engineer",
    jobPeriod: "Part-time",
    jobLocation: "Remote",
  },
  {
    id: "32c4d89c-2781-4e36-952f-0fb2b9e34b49",
    jobCategory: "Engineering",
    jobPosition: "Senior, Backend Developer",
    jobPeriod: "Full-time",
    jobLocation: "Lagos",
  },
  {
    id: "9f73747e-b806-464f-b742-3412fef95012",
    jobCategory: "Engineering",
    jobPosition: "Senior, DevOps Engineer",
    jobPeriod: "Full-time",
    jobLocation: "Lagos",
  },
];

export function getJobs() {
  return jobs;
}

export function getJob(id) {
  return jobs.find((job) => job.id === id);
}
