import React from 'react'
import SingleJob from '../Components/SingleJob'

function SingleJobPage () {
    return (
        <main>
            <SingleJob/>
        </main>
    )
}

export default SingleJobPage