// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c-image1,
.c-image3 {
  width: 261px;
  height: 171px;
}
.c-image1 img,
.c-image3 img,
.c-image2 img,
.c-image4 img {
  width: 100%;
  height: 100%;

  object-fit: contain;
}

.c-image2 {
  width: 222px;
  height: 222px;
  position: relative;
  left: 2rem;
}

.c-image4 {
  width: 218px;
  height: 310px;
  position: absolute;
  bottom: -12.4rem;
  left: -2rem;
}
.c-image5 {
  margin-left: auto;
}
.c-image4 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.creators.component-content-image-container {
  position: relative;
  width: 50%;
  flex-direction: column;
}

.creator-top-image,
.creator-bottom-image {
  display: flex;
  justify-content: space-between;
}

.c-image3 {
  position: relative;
  left: 5rem;
  top: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Creators/style.css"],"names":[],"mappings":"AAAA;;EAEE,YAAY;EACZ,aAAa;AACf;AACA;;;;EAIE,WAAW;EACX,YAAY;;EAEZ,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;AACb;AACA;EACE,iBAAiB;AACnB;AACA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,sBAAsB;AACxB;;AAEA;;EAEE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;AACX","sourcesContent":[".c-image1,\n.c-image3 {\n  width: 261px;\n  height: 171px;\n}\n.c-image1 img,\n.c-image3 img,\n.c-image2 img,\n.c-image4 img {\n  width: 100%;\n  height: 100%;\n\n  object-fit: contain;\n}\n\n.c-image2 {\n  width: 222px;\n  height: 222px;\n  position: relative;\n  left: 2rem;\n}\n\n.c-image4 {\n  width: 218px;\n  height: 310px;\n  position: absolute;\n  bottom: -12.4rem;\n  left: -2rem;\n}\n.c-image5 {\n  margin-left: auto;\n}\n.c-image4 img {\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n}\n\n.creators.component-content-image-container {\n  position: relative;\n  width: 50%;\n  flex-direction: column;\n}\n\n.creator-top-image,\n.creator-bottom-image {\n  display: flex;\n  justify-content: space-between;\n}\n\n.c-image3 {\n  position: relative;\n  left: 5rem;\n  top: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
