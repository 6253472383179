import "./style.css";
import whatsapp from "../../assets/whatsapp.svg"

export const PrimaryBtn = () => {
  return <button className="btn primaryBtn">Get Step</button>;
};
export const NavBtn = () => {
  return <button className="btn navBtn">Get Step</button>;
};
export const CareersBtn = () => {
  return <button className="btn primaryBtn">Explore open position</button>;
};
export const JoinBtn = () => {
  return <button className="btn primaryBtn">Join our team</button>;
};
export const ApplyBtn = () => {
  return <button className="btn primaryBtn">Apply Now</button>;
};
export const SendBtn = () => {
  return <button className="btn primaryBtn">Send</button>;
};
export const AccessBtn = () => {
  return (
    <button className="btn primaryBtn accessBtn">
      Get Access to <strong>STEP</strong> Premium
    </button>
  );
};

export const TellAFriend = () => {
  return (
    <button className="btn tellAFriend">
      <span>
      <img src={whatsapp} alt="" />
      </span>
      <span>
        Tell a friend
      </span>
    </button>
  )
}
