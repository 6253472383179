// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
  border: none;
  outline: none;
  border-radius: 2.8rem;
  font-weight: 500;
  cursor: pointer;
  /* display: flex; */
  justify-content: center;
}

.primaryBtn {
  background: #515acd;
  color: #fff;
  border-radius: 5rem;
  padding: 1.5rem 2rem;
  font: normal normal 22px Montserrat;
  width: 148px;
  height: 65px;
}

.tellAFriend {
  background: #515acd26 0% 0% no-repeat padding-box;
  color: #515acd;
  border-radius: 5rem;
}

.tellAFriend span:last-of-type {
  margin-top: 0.3rem;
  margin-left: 1rem;
}

.navBtn {
  background: #515acd;
  padding: 1rem 1.5rem;
  color: #fff;
  font: normal normal 16px Montserrat;
  width: 134px;
  height: 49px;
}

.accessBtn {
  width: 400px;
  height: 82px;
  font: 600 normal normal 28px/34px Montserrat;
  margin-top: 4.8rem;
}

@media only screen and (max-width: 768px) {
  .accessBtn {
    width: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Buttons/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,qBAAqB;EACrB,gBAAgB;EAChB,eAAe;EACf,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,mBAAmB;EACnB,oBAAoB;EACpB,mCAAmC;EACnC,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,iDAAiD;EACjD,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,oBAAoB;EACpB,WAAW;EACX,mCAAmC;EACnC,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,4CAA4C;EAC5C,kBAAkB;AACpB;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".btn {\n  border: none;\n  outline: none;\n  border-radius: 2.8rem;\n  font-weight: 500;\n  cursor: pointer;\n  /* display: flex; */\n  justify-content: center;\n}\n\n.primaryBtn {\n  background: #515acd;\n  color: #fff;\n  border-radius: 5rem;\n  padding: 1.5rem 2rem;\n  font: normal normal 22px Montserrat;\n  width: 148px;\n  height: 65px;\n}\n\n.tellAFriend {\n  background: #515acd26 0% 0% no-repeat padding-box;\n  color: #515acd;\n  border-radius: 5rem;\n}\n\n.tellAFriend span:last-of-type {\n  margin-top: 0.3rem;\n  margin-left: 1rem;\n}\n\n.navBtn {\n  background: #515acd;\n  padding: 1rem 1.5rem;\n  color: #fff;\n  font: normal normal 16px Montserrat;\n  width: 134px;\n  height: 49px;\n}\n\n.accessBtn {\n  width: 400px;\n  height: 82px;\n  font: 600 normal normal 28px/34px Montserrat;\n  margin-top: 4.8rem;\n}\n\n@media only screen and (max-width: 768px) {\n  .accessBtn {\n    width: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
