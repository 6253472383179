import React, { useState } from "react";
import { PrimaryBtn } from "../Buttons/Buttons";
import Modal from "react-modal";

import closeIcon from "../../assets/closeIcon.svg";
import GetStepModal from "../GetStepModal";
// styling
import "./style.css";
import heroImage from "../../assets/hero-section-img.png";
// import caret from "../../assets/caret-down.svg";

function Hero() {
  Modal.setAppElement("#root");
  Modal.defaultStyles.overlay.backgroundColor = "white";
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  // const scroll = () => {
  //   window.scrollTo({
  //     top: 800,
  //     behavior: "smooth",
  //   });
  // };
  return (
    <section className="section-wrapper">
      <div className="container">
        <div className="section-content">
          <div className="hero-text-content">
            <p className='title'>
              The <span>Social</span> <br /> <span>Payment</span> App
            </p>
            <h3>For a Brave New World</h3>
            <p className="connect">
              Connect, Interact & Transact with Friends, Creators & Businesses
            </p>
            <div className="hero-CTA-btns">
              <span onClick={openModal}>
                <PrimaryBtn />
              </span>
            </div>
            {/* <div className="toTop">
              <img src={caret} alt="" />
            </div> */}
          </div>
          <div className="hero-image-content">
            <img src={heroImage} alt="hero section img" />
          </div>
          <Modal
            isOpen={modalIsOpen}
            className="GetStepModal"
            overlayClassName="Overlay"
          >
            <div className="getStep modal-close-btn">
              <img src={closeIcon} alt="" onClick={closeModal} />
            </div>
            <div className="getStep-modal-content-container">
              <GetStepModal />
            </div>
          </Modal>
        </div>
      </div>
    </section>
  );
}

export default Hero;
