import React from 'react'
import { Outlet } from 'react-router-dom'
import WebNav from '../Components/Navbar/WebNav'

function MainLayout() {
  return (
    <>
    <WebNav/>
    <Outlet/>
    </>
  )
}

export default MainLayout