import React, { useEffect, useState } from "react";
import "./style.css";
import { Document, Page, pdfjs } from 'react-pdf';

import pdf from './../../assets/BuntuPrivacyPolicy.pdf';




function Privacy() {
  const [numPages, setNumPages] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }




  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc =`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;  },[])

  useEffect(() => {
    window.addEventListener('resize', (event) => {
      console.log(event)
      setWidth(event.target.innerWidth)
    },[window])

    return () => { window.removeEventListener('resize', (event) => {}) }

  },[])

  return (
    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
    <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}
    >
      {Array.from(
        new Array(numPages),
        (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={width * 0.8}
          />
        ),
      )}
    </Document>
    {/* <section className="privacy-wrapper">
      <div className="privacy-container">
        <div className="privacy-content">
          <div className="privacy-heading">
            <h1>Privacy policy</h1>
            <p>Effective date: March 14, 2020</p>
          </div>
          <div className="privacy-introduction">
            <p>
              Step Inc. ("us", "we", or "our") operates the Step web application
              (hereinafter referred to as the "Service").
            </p>
            <br />
            <p>
              This page informs you of our policies regarding the collection,
              use, and disclosure of personal data when you use our Service and
              the choices you have associated with that data. The Privacy Policy
              for Step is based on the Terms Feed’s Privacy Policy Template.
            </p>
            <br />
            <p>
              We use your data to provide and improve the Service. By using the
              Service, you agree to the collection and use of information in
              accordance with this policy. Unless otherwise defined in this
              Privacy Policy, the terms used in this Privacy Policy have the
              same meanings as in our Terms and Conditions.
            </p>
          </div>
          <div className="privacy-hyperlinks">
            <h3>Contents:</h3>
            <ol className="hyperlinks-container">
              <li>
                <a href="#content1">Categories of personal data we collect</a>
              </li>
              <li>
                <a href="#index">
                  For what purposes we process your personal data
                </a>
              </li>
              <li>
                <a href="#index">
                  Under what legal bases we process your personal data (Applies
                  only to EEA-based users)
                </a>
              </li>
              <li>
                <a href="#index">With whom we share your personal data</a>
              </li>
              <li>
                <a href="#index">How you can exercise your privacy rights</a>
              </li>
              <li>
                <a href="#index">Age limitation</a>
              </li>
              <li>
                <a href="#index">International data transfers</a>
              </li>
              <li>
                <a href="#index">Changes to this privacy policy</a>
              </li>
              <li>
                <a href="#index">Data retention</a>
              </li>
              <li>
                <a href="#index">How “do not track” requests are handled</a>
              </li>
              <li>
                <a href="#index">Personal data controller</a>
              </li>
              <li>
                <a href="#index">Contact us</a>
              </li>
            </ol>
          </div>
          <div className="privacy-hyperlinks-description">
            <ol>
              <li id="content1">
                <h3>Categories of personal data we collect</h3>
                <p>
                  We collect data you give us voluntarily (for example, when you
                  choose your areas for improvement or send us an email). We
                  also may receive data about you from third parties (for
                  example, when you sign in via Apple). Finally, we collect data
                  automatically (for example, your IP address).
                </p>
                <div className="nested-links">
                  <h4>1.1 Data you give us</h4>
                  <p>
                    You provide us information about yourself when you register
                    for and/or use the Service. For example: name, age, gender,
                    data on physical characteristics (including height, weight,
                    areas for improvement), fitness level, food preferences
                    (including diet preferences; preferred number of meals,
                    foods you do not like), meditation preferences, food and
                    exercises you log, email address.
                  </p>
                </div>
                <div className="nested-links">
                  <h4>1.2 Data provided by third parties</h4>
                  <p>
                    When you use sign in with Apple to register an account in
                    the App, we get personal data from your Apple ID account.
                    This data may include, in particular, your name and verified
                    email address. You may choose to share your real email
                    address or an anonymous one that uses the private email
                    relay service. Apple will show you their detailed privacy
                    information on the sign in with Apple screen. Find more
                    about sign with Apple here.
                  </p>
                </div>
                <div className="nested-links">
                  <h4>1.3 Data we collect automatically:</h4>
                  <ol type="a" className="alphabet-list">
                    <li>
                      Data about how you found us.
                      <p>
                        We collect data about your referring app or URL (that
                        is, the app or place on the Web where you were when you
                        tapped on our ad).
                      </p>
                    </li>
                    <li>
                      Device and Location data
                      <p>
                        {" "}
                        We collect data from your mobile device. Examples of
                        such data include: language settings, IP address, time
                        zone, type and model of a device, device settings,
                        operating system, Internet service provider, mobile
                        carrier, hardware ID, and Facebook ID.
                      </p>
                    </li>
                  </ol>
                </div>
              </li>
              <li>
                <h3>For what purposes we process your personal data</h3>
                <p>We process your personal data:</p>

                <div className="nested-links">
                  <h4>2.1 To provide our Service</h4>
                  <p>
                    This includes enabling you to use the Service in a seamless
                    manner and preventing or addressing Service errors or
                    technical issues.
                  </p>
                  <p>
                    To host personal data and enable our App to operate and be
                    distributed we use Amazon Web Services, which is a hosting
                    and backend service provided by Amazon.
                  </p>
                  <p>
                    To monitor infrastructure and the App’s performance, we use
                    Crashlytics, which is a monitoring service provided by
                    Google. Here’s its Data Collection Policy.
                  </p>
                  <p>
                    We use Firebase Performance Monitoring and Firebase Crash
                    Reporting, which are monitoring services provided by Google.
                    To learn more, please visit Google’s Privacy policy and
                    Privacy and Security in Firebase.
                  </p>
                </div>
                <div className="nested-links">
                  <h4>2.2 To customize your experience</h4>
                  <p>
                    We process your personal data, in particular, such as your
                    characteristics and preferences, to adjust the content of
                    the Service and provide content tailored to your personal
                    preferences. As a result of such processing, you get access,
                    for example, to a nutrition plan containing only vegetarian
                    products or a customized meal plan offer (in the in-App
                    chat) tailored for achieving your goals.
                  </p>
                </div>
                <div className="nested-links">
                  <h4>
                    2.3 To manage your account and provide you with customer support
                  </h4>
                  <p>
                    We process your personal data to respond to your requests
                    for technical support, Service information or to any other
                    communication you initiate. This includes accessing your
                    account to address technical support requests. For this
                    purpose, we may send you, for example, notifications or
                    emails about the performance of our Service, security,
                    payment transactions, notices regarding our Terms and
                    Conditions of Use or this Privacy Policy.
                  </p>
                </div>
              </li>
              <li>
                <h3>With whom we share your personal data</h3>
                <p>
                  We share information with third parties that help us operate,
                  provide, improve, integrate, customize, support, and market
                  our Service. We may share some sets of personal data, in
                  particular, for purposes indicated in Section 2 of this
                  Privacy Policy. The types of third parties we share
                  information with include, in particular:
                </p>

                <div className="nested-links">
                  <h4>3.1 Service providers</h4>
                  <p>
                    We share personal data with third parties that we hire to
                    provide services or perform business functions on our
                    behalf, based on our instructions. We share your personal
                    information with the following types of service providers:
                  </p>
                  <ul className="unordered-list">
                    <li>cloud storage providers (Amazon)</li>
                    <li>
                      data analytics providers (Facebook, Google, Appsflyer,
                      Firebase, Crashlytics, Amplitude, Fabric)
                    </li>
                    <li>
                      data analytics providers (Facebook, Google, Appsflyer,
                      Firebase, Crashlytics, Amplitude, Fabric)
                    </li>
                  </ul>
                </div>
                <div className="nested-links">
                  <h4>
                    3.2 Apple Health Kit (and Apple Motion & Fitness API) or Google
                    Fit (together the “Health App”)
                  </h4>
                  <p>
                    Provided you give express permission on your device, we may
                    receive (read) or/and write (share) data about your activity
                    with/from Health App. In some of our Apps you may decide to
                    allow us to read (receive) the following information from
                    the Health App: the number of steps traveled and weight.
                  </p>
                  <p>
                    If you grant access for us to write data to (share with)
                    Health App, we will transfer to Health App information on
                    your workouts, weight, dietary energy (calorie intake
                    according to information on how you follow your meal plan).
                    Before you decide to share your data with the Health App, we
                    encourage you to review their privacy policy, as your data
                    will be subject to those policies. For more information on
                    the Apple HealthKit, please see
                    http://www.apple.com/ios/health/, and on the Google Fit,
                    please see https://www.google.com/fit/.
                  </p>
                </div>
                <div className="nested-links">
                  <h4>
                    3.3 To manage your account and provide you with customer support
                  </h4>
                  <p>
                    We process your personal data to respond to your requests
                    for technical support, Service information or to any other
                    communication you initiate. This includes accessing your
                    account to address technical support requests. For this
                    purpose, we may send you, for example, notifications or
                    emails about the performance of our Service, security,
                    payment transactions, notices regarding our Terms and
                    Conditions of Use or this Privacy Policy.
                  </p>
                </div>
              </li>
              <li>
                <h3>Contact us</h3>
                <p>
                  You may contact us at any time for details regarding this
                  Privacy Policy and its previous versions. For any questions
                  concerning your account or your personal data please contact
                  us at support@step.com.
                </p>
                <p>Effective as of: 17 December 2020</p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section> */}
    </div>
  );
}

export default Privacy;
