import React, { useEffect } from "react";
import FAQ from "../Components/FAQ";
import Footer from "../Components/Footer/Footer";
import Mission from "../Components/Mission/Mission";

function FAQpage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <main className="main-page">
        <FAQ />
        <Mission />
      </main>
      <Footer />
    </>
  );
}

export default FAQpage;
