import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ApplyBtn } from "../Buttons/Buttons";
import Modal from "react-modal";
import "./style.css";
import { getJob } from "../../MOCK_DATA";
import closeIcon from "../../assets/closeIcon.svg";
import CareerForm from "../Forms/CareerForm";

function SingleJob() {
  Modal.setAppElement("#root");
  Modal.defaultStyles.overlay.backgroundColor = "white";
  let { jobID } = useParams();
  let job = getJob(jobID);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
    console.log("Close Modal");
  };
  return (
    <section className="singleJob-wrapper">
      <div className="container">
        <div className="singleJob-content">
          <div className="singleJob-content-header">
            <h1 className="title">{job.jobPosition}</h1>
            <p className="subtitle">
              <span>{job.jobPeriod}</span>
              <span>{job.jobCategory}</span>
              <span>{job.jobLocation}</span>
            </p>
          </div>
          <div className="singleJob-content-body">
            <article>
              <h3 className="title">ABOUT US:</h3>
              <p className="body-paragraph">
                <strong>STEP</strong> is a leading community driven platform in
                the world with over 100 million installs (iOS+Android). We have
                7.5 million followers on our social media platforms – more than
                any other competitor has. This all became possible through a
                team of world-class talented professionals in composition 100+
                people in Lagos, Nigeria. We’re one of the largest partners of
                Facebook / Google / Snapchat / Twitter from CEE.
              </p>
              <p className="body-paragraph">
                <strong>Our mission is creating happiness within</strong> since
                it’s vital to have your mind and body in harmony. There are 500
                million people in the world who value a healthy lifestyle. We
                believe that every one of those people should be a Step user. We
                plan to capture the growth of the Global Social Market, and our
                ideal candidate will focus on building the largest Social
                company in the world.
              </p>
              <p className="body-paragraph">
                <strong>
                  Financial controller is one of thr key specialists in Finance
                  contributing to the department development and performing a
                  vast variety of controlling related activities.
                </strong>
                You will liaise with Finance, Treasury, Legal and Operations
                teams to a) help to develop and implement processes necessary
                for operation of controlling function and b) support the
                Operations teams on financial matters.
              </p>
            </article>
            <article>
              <h3 className="title">ABOUT YOU:</h3>
              <ul className="dot-points">
                <li>
                  Shape the architecture and visual identity of a game interface
                  together
                </li>
                <li>
                  Conceptualize and implement the design of in-game menus and
                  user interfaces, with understanding of technical aspects of
                  mobile game development
                </li>
                <li>
                  Create wireframes, flows, logic trees for player experience
                  and menu
                </li>
                <li>Work closely with Game Designer, Artists and Animators</li>
                <li>
                  Create final interface layouts and write supporting
                  documentation
                </li>
                <li>Create UI kit</li>
              </ul>
            </article>
            <article>
              <h3>REQUIREMENTS:</h3>
              <ul className="dot-points">
                <li>
                  Shape the architecture and visual identity of a game interface
                  together
                </li>
                <li>
                  Conceptualize and implement the design of in-game menus and
                  user interfaces, with understanding of technical aspects of
                  mobile game development
                </li>
                <li>
                  Create wireframes, flows, logic trees for player experience
                  and menu
                </li>
                <li>Work closely with Game Designer, Artists and Animators</li>
                <li>
                  Create final interface layouts and write supporting
                  documentation
                </li>
                <li>Create UI kit</li>
              </ul>
            </article>
          </div>
          <div className="applyBtn" onClick={openModal}>
            <ApplyBtn />
          </div>
          <div className="modal-component">
            <Modal
              isOpen={modalIsOpen}
              className="Modal"
              overlayClassName="Overlay"
            >
              <div className="modal-close-btn">
                <img src={closeIcon} alt="" onClick={closeModal} />
              </div>
              <div className="modal-content-container">
                <div className="modal-content-text">
                  <h3>We make the world better. Join us.</h3>
                  <p>
                    Send us your CV and contact information, and we’ll get back
                    to you soon. We look forward to getting to know you!
                  </p>
                </div>
                <div className="modal-content-form">
                  <CareerForm/>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SingleJob;
