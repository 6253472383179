import React, { useState } from "react";
import { SendBtn } from "../../Buttons/Buttons";
import "./style.css";

function CareerForm() {
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    position: "",
    message: "",
  });

  const handleChange = (event) => {
    event.preventDefault();
    const name = event.target.name;
    const value = event.target.value;

    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault(); 
  }

  return (
    <form className="form-wrapper">
      <div className="inputField-group">
        <input
          type="text"
          name="name"
          value={userInfo.name}
          placeholder="Your name"
          onChange={handleChange}
        />
        <input
          type="email"
          name="email"
          value={userInfo.email}
          placeholder="Email"
          onChange={handleChange}
        />
        <input
          type="file"
          name=""
          //   value={userInfo.name}
          placeholder="CV/Resume"
        />
        <input
          type="text"
          name="position"
          value={userInfo.position}
          placeholder="Position"
          onChange={handleChange}
        />
      </div>
      <div className="textfield-group">
        <textarea placeholder="Message"></textarea>
      </div>
      <div className="sendBtn" onClick={handleSubmit}>
        <SendBtn/>
      </div>
    </form>
  );
}

export default CareerForm;
