import React from "react";
import "./style.css";
import { Questions } from "./Questions";
import faq from "../../assets/faq.png";
import { FiPlus, FiMinus } from "react-icons/fi";
import { useState } from "react";

function index() {
  return (
    <section className="component-wrapper">
      <div className="container">
        <div className="component-content">
          <div className="faq component-content-header">
            <h1 className="title">
              Frequently <br />
              <span className="faq-img">
                <img src={faq} alt="" />
              </span>{" "}
              Asked <br /> Questions
            </h1>
          </div>
        </div>
        <div className="component-accordion">
          <Accordion />
        </div>
      </div>
    </section>
  );
}

export default index;

export const Accordion = () => {
  const [activeQuestion, setActiveQuestion] = useState(false);

  const toggleQuestion = (index) => {
    if (activeQuestion === index) {
      return setActiveQuestion(null);
    }

    setActiveQuestion(index);
  };
  return (
    <div className="accordion-container">
      {Questions.map((question, index) => {
        return (
          <div key={question.id} className="accordion-content">
            <div
              className="accordion-heading"
              onClick={() => toggleQuestion(index)}
            >
              <span>{activeQuestion === index ? <FiMinus /> : <FiPlus />}</span>
              <h3>{question.question}</h3>
            </div>
            {activeQuestion === index ? (
              <div className="accordion-text">
                <p>{question.answer}</p>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};
