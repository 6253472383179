// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.component-accordion {
width: 90%;
margin: 0 auto 12rem;
}

.accordion-content {
  background: transparent;
  border: 0.75px solid #d1d5db;
  border-radius: 50px;
  padding: 4rem;
  color: black;
  margin-bottom: 2rem;
  width: 100%;
}

.accordion-heading {
  display: flex;
  align-items: center;
  font: normal normal 600 20px/44px Montserrat;
  letter-spacing: 0px;
  color: #161e2e;
  cursor: pointer;
  transition: ease-out .3s;
}

.accordion-heading span {
  margin-right: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordion-text {
  text-align: left;
  font: normal normal 18px/34px Montserrat;
  letter-spacing: 0px;
  color: #242121;
}

.faq.component-content-header {
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/FAQ/style.css"],"names":[],"mappings":"AAAA;AACA,UAAU;AACV,oBAAoB;AACpB;;AAEA;EACE,uBAAuB;EACvB,4BAA4B;EAC5B,mBAAmB;EACnB,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,4CAA4C;EAC5C,mBAAmB;EACnB,cAAc;EACd,eAAe;EACf,wBAAwB;AAC1B;;AAEA;EACE,oBAAoB;EACpB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,wCAAwC;EACxC,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".component-accordion {\nwidth: 90%;\nmargin: 0 auto 12rem;\n}\n\n.accordion-content {\n  background: transparent;\n  border: 0.75px solid #d1d5db;\n  border-radius: 50px;\n  padding: 4rem;\n  color: black;\n  margin-bottom: 2rem;\n  width: 100%;\n}\n\n.accordion-heading {\n  display: flex;\n  align-items: center;\n  font: normal normal 600 20px/44px Montserrat;\n  letter-spacing: 0px;\n  color: #161e2e;\n  cursor: pointer;\n  transition: ease-out .3s;\n}\n\n.accordion-heading span {\n  margin-right: 2.4rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.accordion-text {\n  text-align: left;\n  font: normal normal 18px/34px Montserrat;\n  letter-spacing: 0px;\n  color: #242121;\n}\n\n.faq.component-content-header {\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
