import React, { useEffect } from "react";

// components
import Hero from "../Components/HeroSection/Hero";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Hero />
    </>
  );
}

export default Home;
