import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import logo from "../../assets/logo.png";
import verifySuccess from "../../assets/verfiySuccess.svg";
import verifyFail from "../../assets/verifyFail.svg";
import axios from "axios";
import "./style.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ConfirmEmail() {
  const errorNotify = (text) => toast.error(text);
  const successNotify = (text) => toast.success(text);

  const [loading, setLoading] = useState(true);
  const [isVerified, setIsVerified] = useState(false);
  const [isNotVerified, setIsNotVerified] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const encode = encodeURIComponent(
      searchParams.get("token").replace(/\s/g, "+")
    );
    const email = encodeURIComponent(
      searchParams.get("email").replace(/\s/g, "+")
    );
    axios
      .get(
        "https://api-dev.getstep.app/api/v1/Auth/confirm-email?email=" +
          email +
          "&token=" +
          encode
      )
      .then((res) => {
        setLoading(false);
        setIsVerified(true);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setIsNotVerified(true);
      });
  }, [searchParams]);

  const resendCode = async () => {
    const email = encodeURIComponent(
      searchParams.get("email").replace(/\s/g, "+")
    );
    await axios
      .post(
        `https://api-dev.getstep.app/api/v1/Auth/resend-confirm-link?email=${email}`
      )
      .then((res) => {
        console.log(res);
        res.status === 200 &&
          successNotify("Verification code sent sucessfully");
      })
      .catch((err) => {
        console.log(err);
        err.response.status === 404 && errorNotify(err.response.statusText);
      });
  };

  return (
    <>
      <ToastContainer style={{ fontSize: "18px", fontWeight: "500" }} />
      {loading && (
        <div className="spinner-container">
          <div className="loading-spinner"></div>
        </div>
      )}

      <div className="cE-wrapper">
        {isVerified && (
          <>
            <div className="logo">
              <img src={logo} alt="" />
            </div>
            <div className="mail-img">
              <img src={verifySuccess} alt="" />
            </div>
            <div className="mail-text">
              <h3>Email Confirmed</h3>
              <p>
                Your email{" "}
                <span>{searchParams.get("email").replace(/\s/g, "+")}</span> has
                been successfully confirmed. Click on the “Proceed to continue”
                button to open the app
              </p>
            </div>
            <Link style={{ color: "white" }} to="/">
              <button className="cE-btn">Proceed to continue</button>
            </Link>
          </>
        )}
        {!loading && isNotVerified && (
          <>
            <div className="logo">
              <img src={logo} alt="" />
            </div>
            <div className="mail-img">
              <img src={verifyFail} alt="" />
            </div>
            <div className="mail-text">
              <h3>Email Confirmation Failed</h3>
              <p>
                Your email{" "}
                <span>{searchParams.get("email").replace(/\s/g, "+")}</span>{" "}
                confirmation failed. Click on the “Resend confirmation email” to
                resend confirmation link/code to your email.
              </p>
            </div>
            <button className="cE-btn" onClick={resendCode}>
              Resend confirmation email
            </button>
          </>
        )}
      </div>
    </>
  );
}

export default ConfirmEmail;
