import React, {useEffect} from "react";
import Creators from "../Components/Creators";

function CreatorsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <main className="main-page">
        <Creators />
      </main>
    </>
  );
}

export default CreatorsPage;
