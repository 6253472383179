import React from "react";
import Careers from "../Components/Careers/";
import Footer from "../Components/Footer/Footer";

function CareersPage() {
  return (
    <>
      <main>
        <Careers />
      </main>
      <Footer />
    </>
  );
}

export default CareersPage;
