import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home";
import PrivacyPage from "./Pages/PrivacyPage";
import TermsAndConditions from "./Pages/TermsAndConditions";
import ContactPage from "./Pages/Contact";
import CareersPage from "./Pages/CareersPage";
import SingleJobPage from "./Pages/SingleJob";
import BusinessesPage from "./Pages/Businesses";
import CreatorsPage from "./Pages/CreatorsPage";
import FAQpage from "./Pages/FAQpage";
import ConfirmEmailPage from "./Pages/ConfirmEmailPage";
import MainLayout from "./Layouts/MainLayout";
// import Breadcrumbs from "./Components/Breadcrumbs/Breadcrumbs";

function App() {
  // const routes = [
  //   {
  //     path: "/",
  //     element: <Home />,
  //   },
  //   {
  //     path: "/privacy-policy",
  //     element: <PrivacyPage />,
  //   },
  //   {
  //     path: "/contact-us",
  //     element: <ContactPage />,
  //   },
  //   {
  //     path: "/careers",
  //     element: <CareersPage />,
  //   },
  //   {
  //     path: "/businesses",
  //     element: <BusinessesPage />,
  //   },
  //   {
  //     path: "/creators",
  //     element: <CreatorsPage />,
  //   },
  //   {
  //     path: "/faq",
  //     element: <FAQpage />,
  //   },
  //   {
  //     path: "/terms-and-conditions",
  //     element: <TermsAndConditions />,
  //   },
  // ];

  // const breadcrumbs = useBreadcrumbs();
  return (
    <>
      <Router>
        {/* <Breadcrumbs /> */}
        <Routes>
          <Route element={<MainLayout />}>
            <Route exact path="/" element={<Home />} />
            <Route path="/privacy-policy" element={<PrivacyPage />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/contact-us" element={<ContactPage />} />
            <Route path="/careers" element={<CareersPage />} />
            <Route path="careers/:jobID" element={<SingleJobPage />} />
            <Route path="/businesses" element={<BusinessesPage />} />
            <Route path="/creators" element={<CreatorsPage />} />
            <Route path="/faq" element={<FAQpage />} />
          </Route>
          <Route path="/ConfirmEmail" element={<ConfirmEmailPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
