import React, { useEffect } from "react";
import ContactUs from "../Components/ContactUs";
import Mission from "../Components/Mission/Mission";
import Footer from "../Components/Footer/Footer";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <main className="main-page">
        <ContactUs />
        <Mission />
      </main>
      <Footer />
    </>
  );
}

export default Contact;
