import React from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/logo.webp";
import "./style.css";

function Footer() {
  return (
    <footer className="footer-wrapper">
      <div className="container">
        <div className="footer-container">
          <div className="footer-logo">
            <Link to="/">
              <img src={logo} alt="" />
            </Link>
          </div>
          <div className="footer-content">
            <div className="footer-title">
              <h3>
                The Social Payment <br /> App
              </h3>
              <p>for the global citizen</p>
            </div>
            <ul className="footer-links">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/businesses">Businesses</Link>
              </li>
              <li>
                <Link to="/creators">Creators</Link>
              </li>
              <li>Community</li>
            </ul>
          </div>
          <div className="footer">
            <ul className="footer-bottom-links">
              <Link to="/terms-and-conditions">
                <li>Terms of Service</li>
              </Link>
              <Link to="/privacy-policy">
                <li>Privacy Policy</li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
