export const Questions = [
  {
    id: 1,
    question: "What is Step?",
    answer:
      "Our community is built on trust. Whether you’re a consumer or a business owner, you put your trust in us to provide information people can rely on to make good decisions. We work hard to maintain that trust, and make STEP truly helpful for everyone.",
  },
  {
    id: 2,
    question: "How do i create an account?",
    answer:
      "Our community is built on trust. Whether you’re a consumer or a business owner, you put your trust in us to provide information people can rely on to make good decisions. We work hard to maintain that trust, and make STEP truly helpful for everyone.",
  },
  {
    id: 3,
    question: "What distinguishes STEP and what we do from others",
    answer:
      "Our community is built on trust. Whether you’re a consumer or a business owner, you put your trust in us to provide information people can rely on to make good decisions. We work hard to maintain that trust, and make STEP truly helpful for everyone.",
  },
];
