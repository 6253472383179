import React, {useEffect} from "react";
import Businesses from "../Components/Businesses";

function BusinessesPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <main className="main-page">
        <Businesses />
      </main>
    </>
  );
}

export default BusinessesPage;
