// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

.container {
  max-width: 89%;
  margin: 0 auto;
}

main {
  background: #fafaff;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  z-index: 10;
  position: relative;
  top: 2rem;
}

#root {
  width: 1140px;
  height: 100vh;
  margin: auto;
  background: #fafaff 0% 0% no-repeat padding-box;
  /* border-radius: 0px 0px 30px 30px; */
  opacity: 1;
  /* min-height: 600px; */
  /* border: 1px solid lightgray; */
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.overlay-btn {
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
}

.overlay-btn .primaryBtn {
  padding: 1.5rem 3rem;
  font-size: 20px;
  font-weight: 500;
}

@media only screen and (max-width: 1024px) {
  .container {
    max-width: 90%;
  }
}

@media only screen and (max-width: 786px) {
  .container {
    max-width: 92%;
  }
  #root {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,qCAAqC;AACvC;;AAEA;EACE,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,+BAA+B;EAC/B,gCAAgC;EAChC,WAAW;EACX,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,aAAa;EACb,YAAY;EACZ,+CAA+C;EAC/C,sCAAsC;EACtC,UAAU;EACV,uBAAuB;EACvB,iCAAiC;AACnC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,oBAAoB;EACpB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE;IACE,cAAc;EAChB;AACF;;AAEA;EACE;IACE,cAAc;EAChB;EACA;IACE,WAAW;EACb;AACF","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: \"Montserrat\", sans-serif;\n}\n\nhtml {\n  font-size: 10px;\n  scroll-behavior: smooth;\n}\n\n.container {\n  max-width: 89%;\n  margin: 0 auto;\n}\n\nmain {\n  background: #fafaff;\n  border-bottom-left-radius: 25px;\n  border-bottom-right-radius: 25px;\n  z-index: 10;\n  position: relative;\n  top: 2rem;\n}\n\n#root {\n  width: 1140px;\n  height: 100vh;\n  margin: auto;\n  background: #fafaff 0% 0% no-repeat padding-box;\n  /* border-radius: 0px 0px 30px 30px; */\n  opacity: 1;\n  /* min-height: 600px; */\n  /* border: 1px solid lightgray; */\n}\n\nul {\n  list-style: none;\n}\n\na {\n  text-decoration: none;\n}\n\n.overlay-btn {\n  align-items: center;\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  display: flex;\n  justify-content: center;\n}\n\n.overlay-btn .primaryBtn {\n  padding: 1.5rem 3rem;\n  font-size: 20px;\n  font-weight: 500;\n}\n\n@media only screen and (max-width: 1024px) {\n  .container {\n    max-width: 90%;\n  }\n}\n\n@media only screen and (max-width: 786px) {\n  .container {\n    max-width: 92%;\n  }\n  #root {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
