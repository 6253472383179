import React, {useEffect} from 'react'
import TermAndConditions from '../Components/T&Cs'

function TandCPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main>
        <TermAndConditions/>
    </main>
  )
}

export default TandCPage