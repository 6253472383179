import React, { useEffect } from "react";
import { CareersBtn, JoinBtn } from "../Buttons/Buttons";
import culture1 from "../../assets/culture1.png";
import culture2 from "../../assets/culture2.png";
import culture3 from "../../assets/culture3.png";
import communityImage from "../../assets/community-image.png";
import background from "../../assets/career-bg.png";
import arrownDown from "../../assets/caret-down.svg";
import arrow from "../../assets/arrow.svg";
import "./style.css";
import Mission from "../Mission/Mission";
import { Link } from "react-router-dom";
import {getJobs} from "../../MOCK_DATA"

function Careers() {
  let jobs = getJobs()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="careers-wrapper">
      <div className="careers-container">
        <div className="careers-header">
          <h3>
            This is life at <br />
            <span>step.</span>
          </h3>
          <p>Join us and help inspire creativity around the world</p>
          <div className="career-btn">
            <CareersBtn />
          </div>
          <div className="career-background">
            <img src={background} alt="" />
          </div>
        </div>
        <div className="careers-content-container">
          <div className="careers-mainContent">
            <div className="leftContent">
              <div className="top-image">
                <img src={culture2} alt="" />
              </div>
              <div className="bottom-image">
                <img src={culture3} alt="" className="above-image" />
                <img src={culture1} alt="" className="under-image" />
              </div>
            </div>
            <div className="career-text">
              <p className="title">Our philosophy</p>
              <h3>We help people create and build communities.</h3>
              <p>
                We like to be challenged, improve, and grow, individually, and
                as a team. Our shared values and inspirations drive our everyday
                work and shape the products we create.
              </p>
              <div className="career-text-footer">
                <div className="career-text-footer-text">
                  <h3>Fail fast & cheap</h3>
                  <p>Fear of failure can hinder company growth</p>
                </div>
                <div className="career-text-footer-text">
                  <h3>Ideate, test & learn</h3>
                  <p>Come up with an idea, test it, make it better, repeat</p>
                </div>
                <div className="career-text-footer-text">
                  <h3>Speed is everything</h3>
                  <p>
                    Test a hypothesis or develop an approach in record time to
                    stay ahead
                  </p>
                </div>
              </div>
            </div>
            <div className="rightContent">
              <div className="top-image">
                <img src={culture3} alt="" className="above-image" />
                <img src={culture1} alt="" className="under-image" />
              </div>
              <div className="bottom-image">
                <img src={culture2} alt="" />
              </div>
            </div>
          </div>
          <div className="career-overlay-image">
            <img src={communityImage} alt="" />
          </div>
          <div className="career-bottom-section">
            <div className="career-advantage">
              <p className="title">Our advantages</p>
              <h3>Join a team who works as hard as you do.</h3>
              <div className="career-grid-container">
                <div className="advantage">
                  <h3>Fast, unlimited growth</h3>
                  <p>Fear of failure can hinder company growth</p>
                </div>
                <div className="advantage">
                  <h3>Educational feedback</h3>
                  <p>Come up with an idea, test it, make it better, repeat</p>
                </div>
                <div className="advantage">
                  <h3>Opportunities to manifest your ideas</h3>
                  <p>
                    Test a hypothesis or develop an approach in record time to
                    stay ahead
                  </p>
                </div>
                <div className="advantage">
                  <h3>Extremely constructive criticism</h3>
                  <p>Fear of failure can hinder company growth</p>
                </div>
                <div className="advantage">
                  <h3>Create a product that changes lives of millions</h3>
                  <p>Come up with an idea, test it, make it better, repeat</p>
                </div>
                <div className="advantage">
                  <h3>A strong team of like-minded people</h3>
                  <p>
                    Test a hypothesis or develop an approach in record time to
                    stay ahead
                  </p>
                </div>
              </div>
            </div>
            <div className="careers-image">
              <img src={communityImage} alt="" />
            </div>
            <div className="career-position-container">
              <div className="career-position-header">
                <h3>Join our team.</h3>
                <div className="career-position-dropdown">
                  <p>All Departments</p>
                  <img src={arrownDown} alt="" />
                </div>
              </div>
              <div className="career-open-positions">
                <>
                  {jobs.map((position) => {
                    return (
                      <div className="position" key={position.id}>
                        <p className="position-category">
                          {position.jobCategory}
                        </p>
                        <div className="open-position">
                          <div className="position-info">
                            <h3>{position.jobPosition}</h3>
                            <p className="position-location">
                              {position.jobPeriod}, {position.jobLocation}
                            </p>
                          </div>
                          <Link to={`${position.id}`}>
                            <img src={arrow} alt="" />
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </>
              </div>
              <div className="no-career-position">
                <h3>
                  Didn’t find an open <br />
                  position you were looking <br />
                  for? <br />
                  Reach out to us anyway
                </h3>
                <div className="join-btn">
                  <JoinBtn />
                </div>
              </div>
            </div>
            {/* Mission component */}
            <Mission />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Careers;
