import React, {useEffect} from 'react'
import Privacy from '../Components/PrivacyPolicy'

function PrivacyPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main>
        <Privacy/>
    </main>
  )
}

export default PrivacyPage