import React, { useState } from "react";

import { NavLink } from "react-router-dom";

// Helper components
import { NavBtn } from "../Buttons/Buttons";
import Modal from "react-modal";

// Assets & stylings
import "./style.css";
import logo from "../../assets/logo.webp";
import menubtn from "../../assets/Menu.svg";
import closeIcon from "../../assets/closeIcon.svg";
import GetStepModal from "../GetStepModal";
import MobileModal from "./MobileModal";

function WebNav() {
  Modal.setAppElement("#root");
  Modal.defaultStyles.overlay.backgroundColor = "white";
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleToggle = () => {
    setShowModal(!showModal);
  };

  let activeClassName = "active";
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <header className="navbar-wrapper">
      <div className="container">
        <nav className="navbar-container">
          <div className="nav-items-container">
            <NavLink to="/">
              <div className="nav-logo">
                <img src={logo} alt="" />
              </div>
            </NavLink>
            <ul className="nav-links">
              <NavLink
                to="/businesses"
                className={({ isActive }) =>
                  isActive ? activeClassName : undefined
                }
              >
                <li>Businesses</li>
              </NavLink>
              <NavLink to="/creators">
                <li>Creators</li>
              </NavLink>
              <NavLink to="">
                <li>Communities</li>
              </NavLink>
            </ul>
            <div className="CTA-btn">
              <span onClick={openModal}>
                <NavBtn />
              </span>
            </div>
            <div className="hamburger-menu" onClick={handleToggle}>
              <img src={menubtn} alt="" />
            </div>
          </div>
          <Modal
            isOpen={modalIsOpen}
            className="GetStepModal"
            overlayClassName="Overlay"
          >
            <div className="getStep modal-close-btn">
              <img src={closeIcon} alt="" onClick={closeModal} />
            </div>
            <div className="getStep-modal-content-container">
              <GetStepModal />
            </div>
          </Modal>
          {showModal && (
            <MobileModal
              handleToggle={handleToggle}
              setShowModal={setShowModal}
              modalIsOpen={modalIsOpen}
              openModal={openModal}
              closeModal={closeModal}
              closeIcon={closeIcon}
            />
          )}
        </nav>
      </div>
    </header>
  );
}

export default WebNav;
